import Collapsible from "react-collapsible";
import React from "react";

const titleObj = {en: 'Additional Training', fr: 'Formations complémentaires'}

const AdditionalTraining = ({language}) => {

    const title = language === 'EN' ? titleObj.en : titleObj.fr

    const contentEN = (<>
        <li>
            Certificates :
            <ul>
                <li>
                    <a href="https://ti-user-certificates.s3.amazonaws.com/e0df7fbf-a057-42af-8a1f-590912be5460/2d94da9a-45da-4b83-9d97-50b3fd41c102-gregory-dony-e097ed85-55cb-445e-b838-4b5f23683ac1-certificate.pdf">Kubernetes and Cloud Native Associate Exam (KCNA)</a> (July 2023)
                </li>
                <li>
                    Post-graduate certificate in Geomatics (UCL 2016-2017)
                </li>
                <li>Sun Certified Web Component Developer for J2EE 1.4 (March 2008)
                    Brussels
                </li>
                <li>Sun Certified Programmer for the Java 2 Platform, Standard Edition 5.0 (June 2007)
                    Brussels
                </li>
            </ul>
        </li>
        <li>
            Training/Conferences :
            <ul>
                <li>OVHcloud Summit 2023</li>
                <li><a href="https://secappdev.org/">SecAppDev 2016</a></li>
                <li>Devoxx Belgium 2014</li>
                <li>ESRI Worldwide Business Partner Conference 2004 Palm Springs</li>
                <li>ESRI European User Conference 2002 Blankenberge</li>
                <li>Oriented Object Programming and UML. Sydney - Tristar. 09/2002.</li>
            </ul>
        </li>
    </>)

    const contentFR = (
        <>
        <li>
                Certificats :
                <ul>
                    <li>Sun Certified Web Component Developer for J2EE 1.4 (Mars 2008)
                        Bruxelles
                    </li>
                    <li>Sun Certified Programmer for the Java 2 Platform, Standard Edition 5.0 (Juin 2007)
                        Bruxelles
                    </li>
                </ul>
            </li>
            <li>
                Formations/conférences :
                <ul>
                    <li>ESRI Worldwide Business Partner Conference 2004 Palm Springs</li>
                    <li>ESRI European User Conference 2002 Blankenberge</li>
                    <li>Oriented Object Programming and UML. Sydney - Tristar. 09/2002.</li>
                </ul>
            </li>
        </>
    )

    return (
        <Collapsible trigger={title}>
            <ul>
                {language === 'EN' ? contentEN : contentFR}
            </ul>
        </Collapsible>);
};

export default AdditionalTraining;