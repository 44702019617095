import Collapsible from "react-collapsible";
import React from "react";

const titleObj = {en: 'Education', fr: 'Éducation'}

const Education = ({language}) => {

    const title = language === 'EN' ? titleObj.en : titleObj.fr

    const contentEN = (
        <>
            <li>
                <div>Masters degree in Computer Sciences</div>
                <div>
                    <ul>
                        <li>Institution : University of Namur</li>
                        <li>Duration : September 1996 – September 2001</li>
                        <li>Thesis: An abstract interpreter for a sub-language of Java: the project consisted in the
                            implementation in java of a parser, a type-checker and an abstract interpreter of this
                            sub-language
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div>Intermediate in Economics</div>
                <div>
                    <ul>
                        <li>Institution : University of Namur</li>
                        <li>Duration : September 1996 – September 1998</li>
                    </ul>
                </div>
            </li>
            <li>
                <div>High school diploma</div>
                <div>
                    <ul>
                        <li>Institution : College St Joseph, Chimay</li>
                        <li>Duration : September 1990- June 1996</li>
                    </ul>
                </div>
            </li>
        </>
    )

    const contentFR = (
        <>
            <li>
                <div>Maîtrise en informatique</div>
                <div>
                    <ul>
                        <li>Institution : Université de Namur</li>
                        <li>Période : Septembre 1996 – Septembre 2001</li>
                        <li>Sujet de thèse : Un interpréteur abstrait pour un sous-language de Java: le projet
                            consistait à l'implémentation en java d'un parser, un vérificateur de type et un
                            interpréteur abstrait de ce sous-language.
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div>Candidatures en Sciences économiques</div>
                <div>
                    <ul>
                        <li>Institution : Université de Namur</li>
                        <li>Période : Septembre 1996 – Septembre 1998</li>
                    </ul>
                </div>
            </li>
            <li>
                <div>Diplôme d'étude secondaire</div>
                <div>
                    <ul>
                        <li>Institution : Collège St Joseph, Chimay</li>
                        <li>Période : Septembre 1990- Juin 1996</li>
                    </ul>
                </div>
            </li>
        </>
    )
    return (
        <Collapsible trigger={title}>
            <ul>
                {language === 'EN' ? contentEN : contentFR}
            </ul>

        </Collapsible>
    );
};

export default Education;