import Collapsible from "react-collapsible";
import React from "react";

const titleObj = {en: 'Languages', fr: 'Langues'}

const Languages = ({language}) => {

    const title = language === 'EN' ? titleObj.en : titleObj.fr
    const contentEN = (
        <>
            <li>French : Mastery</li>
            <li>English : Advanced</li>
            <li>Dutch : Intermediate</li>
        </>)

    const contentFR = (
        <>
            <li>Français : Maîtrise complète</li>
            <li>Anglais : Autonome</li>
            <li>Néerlandais : Intermédiaire</li>
        </>
    )

    return (
        <Collapsible trigger={title}>
            <ul>
                {language === 'EN' ? contentEN : contentFR}
            </ul>
        </Collapsible>
    );
};

export default Languages;