import { useState } from 'react';
import PersonalInformation from './PersonalInformation'
import Objective from "./Objective";
import Education from "./Education";
import AdditionalTraining from "./AdditionalTraining";
import WorkExperience from "./WorkExperience";
import SkillsSummary from "./SkillsSummary";
import Interests from "./Interests";
import Languages from "./Languages";
import styles from './App.scss';


function getTitle(language) {
    if (language === 'EN') {
        return 'About me'
    } else {
        return 'À propos de moi'
    }
}


function Language({ onCLick, children }) {
    return (
        <button onClick={onCLick}>
             {children}
        </button>
    );
}

export default function DisplayCV() {
    const [language, setLanguage] = useState('EN')
    function switchToEnglish() {
        setLanguage('EN')
    }
    function switchToFrench() {
        setLanguage('FR')
    }

    return (
        <div id='cv'>
            <Language language={language} onCLick={switchToEnglish}>
                EN
            </Language>
            <Language language={language} onCLick={switchToFrench}>
                FR
            </Language>
            <h1 id="title">{getTitle(language)}</h1>
            <PersonalInformation language={language}/>
            <Objective language={language}/>
            <Education language={language}/>
            <AdditionalTraining language={language}/>
            <WorkExperience language={language}/>
            <SkillsSummary language={language}/>
            <Languages language={language}/>
            <Interests language={language}/>
        </div>
    );
}