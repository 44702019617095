import Collapsible from "react-collapsible";
import React from "react";
import styles from "./WorkExperience.scss";

const titleObj = {en: 'Work Experience', fr: 'Expérience professionnelle'}

const WorkExperience = ({language}) => {

        const title = language === 'EN' ? titleObj.en : titleObj.fr

        const experiences = [
            {
                en: {
                    'title': 'Cloud Engineer and CICD Architect at Proximus',
                    'client': 'Proximus (03/19 - 06-24)',
                    'object': (<span>
                        <p>The organization of Proximus is based on an agile model for which the main subdivisions are called "chapters"</p>
                        <p>I was part of the "digital" chapter, responsible for sales, support and partner applications, among other things.</p>
                        <p>I was more specifically part of the "Digital Architecture squad" with about ten colleagues.
                            The main roles I played within this team are explained below.</p>
                    </span>),
                    'role': (
                        <ul>
                            <li><strong>CICD Architect</strong> : I was responsible of the design and implementation of the
                                Continuous Delivery of microservices and frontends using the Gitlab-ci.yml pipeline.
                                It included :
                                <ul>
                                    <li>providing a pipeline able to compile, execute unit test, trigger SonarQube
                                        validation, scan vulnerabilities and deliver docker images on an external registry
                                        (Quay)
                                    </li>
                                    <li>supporting multiple versions of java, spring boot, node.js, angular</li>
                                    <li>supporting the deployment on multiple clusters and multiple environments (dev,
                                        integration, acceptance, ...)
                                    </li>
                                    <li> providing jobs on Jenkins or Openshift used for governance (e.g. gitlab project
                                        configuration), integration tests or adhoc deployments/restarts
                                    </li>
                                </ul>
                            </li>
                            <li><strong>Cloud Engineer</strong> : As a cloud engineer I worked with the team on the setup and the constant evolution of
                                the microservices stack on the OpenShift Platform.
                                <ul>
                                    <li>We gave guidelines about (horizontal) scaling, resource allocations (memory, CPU), external
                                        configuration using config server (we where owner of) or config maps
                                    </li>
                                    <li>We designed and setup Infrastructure As Code using ArgoCD</li>
                                    <li>We provided expertise and provided solution in term of security (certificate & secret
                                        management, encryption, JWT validation,...)
                                    </li>
                                    <li>We implemented Cloud Solutions bases k8s or OpenShift objects (deployments, services, configmaps,...) or products like the Spring Cloud Gateway</li>
                                </ul>
                            </li>
                            <li><strong>Tech lead/ Senor java developer</strong>
                                <p>I worked during 1 year as a Tech Lead for the starting a project called Request
                                    Management. A solution based on even-driven architecture with Kafka as event store, GraphQL and Couchbase</p>
                                <p> As a senior developer, I did a lot of code review, especially of java microservices</p>
                            </li>
                        </ul>),
                    'technologies': (<span>
                        Kubernetes, OpenShift, ArgoCD, CyberArk Conjur, Gitlab-ci, Java 21, SpringBoot 3, NodeJS 16, NestJS, Groovy, REST, GraphQL, Kafka, Couchbase
                    </span>)
                },
                fr: {
                    'title': 'Ingénieur Cloud et Architect CICD à Proximus',
                    'client': 'Proximus (03/19 - 06-24)',
                    'object': (
                        <span>
                            <p>L'organisation, de Proximus est basée sur un modèle agile pour lequel les principales subdivisions sont appelés des "chapter".</p>
                            <p>Je faisais partie du chapitre "digital", responsable entre autres des applications de vente, de support et destinées aux partenaires.</p>
                            <p>Je faisais plus particulièrement partie de la "squad" Digital Architecture avec une dizaine de collègues. Les principaux rôles que j'ai joué au sein de cette équipe
                            sont expliqués ci-dessous.</p>
                        </span>),
                    'role': (
                        <ul>
                            <li><strong>Architecte CICD</strong> : J'étais responsable du design et de l'implémentation du "Continuous Delivery" des microservices et frontends utilisant des pipelines Gitlab-ci.yml.
                                Celà inclut :
                                <ul>
                                    <li>fournir une pipeline capable de compiler, d'exécuter les tests unitaires, lancer des analyses SonarQube, des scans de vulnérabilité et le déploiement
                                        d'une image Docker and un registre externe (Quay)

                                    </li>
                                    <li>supporter de multiples versions de java, spring boot, node.js, angular</li>
                                    <li>supporter le déploiement sur de multiples clusters et dans de multiples environements (dev,
                                        integration, acceptance, ...)
                                    </li>
                                    <li>fournir des jobs Jenkins ou Openshift utilisés pour la gouvernance (par exemple configuration de projet gitlab), des tests d'intégration ou des déploiements/restarts adhoc
                                    </li>
                                </ul>
                            </li>
                            <li><strong>Ingénieur Cloud</strong> : En tant qu'ingénieur Cloud, j'ai travaillé avec
                                l'équipe sur le mise en place et l'évolution constante the la couche microservices
                                <ul>
                                    <li>Nous avons donné des recommendations concernant le scaling (horizontal),
                                        l'allocation de resources (CPU, mémoire), configuration externe via
                                        des config map ou un config server dont nous étions responsable
                                    </li>
                                    <li>Nous avons désigné et fait le setup de l'Infrastructure As Code en utilisant
                                        ArgoCD
                                    </li>
                                    <li>Nous fournissions note expertise et des solutions en terme de sécurity (gestion
                                        des secrets et certificats, encryption, validation de JWT,...)
                                    </li>
                                    <li>Nous avons implémenté de solutions cLoud basées sur les objets k8s et openshift
                                        (config maps, secrets, deployments, services,...) ou des produits tels que le
                                        Spring Cloud Gateway
                                    </li>
                                </ul>
                            </li>
                            <li><strong>Leader technique/Développeur java senior</strong>
                                <p>J'ai travaillé durant 1 an comme leader technique pour le démarrage d'un projet appelé Request
                                    Management. Une solution basée sur une architecture "event-driven" avec Kafka comme agent de messages, GraphQL et Couchbase comme base de donnée NoSQL</p>
                                <p>En tant que développeur senior, j'ai fait beaucoup de code review, particulièrement pour les microservices java en Node.js</p>
                            </li>
                        </ul>),
                    'technologies': (<span>
                        Kubernetes, OpenShift, ArgoCD, CyberArk Conjur, Gitlab-ci, Java 21, SpringBoot 3, NodeJS 16, NestJS, Groovy, REST, GraphQL, Kafka, Couchbase
                    </span>)
                }
            }, {
                en: {
                    'title': 'IAM expert at European Commission',
                    'client': 'EU Login (11/11 – 02/19)',
                    'object': (
                        <span>
    <p>EU Login is the authentication service of the European Commission, allowing users to log in to web and mobile applications of the Commission.</p>
    <p>The service implements single sign-on and supports various protocols such as LDAP, SAML, OAuth 2.0, OIDC, Radius.</p>
    <p>In addition to the password, EU Login offers two-step or two-factor authentication mechanisms (SMS challenge, mobile application for iOS, Android, and Windows Phone, hardware token, software token) as well as federation mechanisms (eID, social networks such as Facebook, Twitter, and Google).</p>
</span>
                    ),
                    'role': (
                        <>
                        <ul>
                        <li>Participation to the complete implementation life-cycle of new Authentication Methods or protocols such as Software or Hardware token, OpenID Connect, Mobile App authentication, ...
                        </li>
                        <li>Commitment to high standards of quality to ensure reliable software solutions. Mastery of unit,
                            integration, and performance testing methodologies; setup of a continuous integration & delivery
                            platform.
                        </li>
                        <li>Delivery of the technical documentation, software release notes presentations to management.
                        </li>
                        <li>Participate in the supervision of evolutive maintenance of the developed software package and
                            provide support, consultancy and advice to the various development teams at the EC.
                        </li>
                    </ul>
                            </>),
                    'technologies': (<span>
                    Java, Oracle RDBMS, SQL, UML, SOA, XML, Jira, Spring Framework, J2EE, Intellij,  Oracle WebLogic Server, Subversion, Hibernate, Confluence, DTD, Web services (SOAP & REST), IAM, Java Virtual Machine,
                        Oracle Weblogic internal security, CAS, OpenId Connect, OAuth2, Web Authentication, SAML, WS-Federation, WS-Trust, SSL/TLS, HTTP, web security, symmetric & asymmetric cryptography including key management - encryption - signature and hashing, LDAP
                    </span>)
                },
                fr: {
                    'title': 'Expert IAM à la Commission Européenne',
                    'client': 'EU Login (11/11 – 02/19)',
                    'object': (
                        <span>
    <p>EU Login est le service d'authentification de la Commission européenne permettant aux utilisateurs de se connecter aux applications web et mobiles de la Commission.</p>
                            <p>Le service met en œuvre le single sign-on et prend en charge différents protocoles tels que LDAP, SAML, OAuth 2.0, OIDC, Radius.</p>
                            <p>En plus du mot de passe, EU Login propose des mécanismes d'authentification à deux étapes ou deux facteurs (challenge SMS, application mobile pour iOS, Android et Windows Phone, jeton matériel, jeton logiciel) ainsi que des mécanismes de fédération (eID, réseaux sociaux tels que Facebook, Twitter et Google).</p>
</span>
                    ),
                    'role': <ul>
                        <li>Participation a l'implémenation de nouvelles méthodes d'authentification ou de nouveaux protocols tels que les token logiciels ou matériels, OpenID Connect, Mobile App authentication, ...
                        </li>
                        <li>Engagement envers des normes élevées de qualité pour garantir des solutions logicielles fiables.
                            Maîtrise des méthodologies de tests unitaires, d'intégration et de performance ; mise en place
                            d'une plateforme d'intégration et de livraison continues.
                        </li>
                        <li>Remise de la documentation technique, des notes de version du logiciel et présentations à la
                            direction.
                        </li>
                        <li>Participation à la supervision de la maintenance évolutive du package logiciel développé et
                            fourniture de support, de conseils et d'expertise aux différentes équipes de développement de la
                            CE.
                        </li>
                    </ul>,
                    'technologies': <span>
                    Java, Oracle RDBMS, SQL, UML, SOA, XML, Jira, Spring Framework, J2EE, Intellij,  Oracle WebLogic Server, Subversion, Hibernate, Confluence, DTD, Web services (SOAP & REST), IAM, Java Virtual Machine,
                        Oracle Weblogic internal security, CAS, OpenId Connect, OAuth2, Web Authentication, SAML, WS-Federation, WS-Trust, SSL/TLS, HTTP, web security, symmetric & asymmetric cryptography including key management - encryption - signature and hashing, LDAP
                    </span>
                }
            },
            {
                en: {
                    'title': 'Java analyst/programmer for Software AG/Acsone',
                    'client': 'European Commission: web content management (04/08 – 11/11)',
                    'object': (
                        <span>
                            <ul>
                                <li>Page and Site Templating: The role of PST language is to define page layout in a separated document so the layout can be reused easily for many documents.
                                Development of the Java engine is made for interpreting this template language to create HTML. Basically this engine will copy XHTML and transform the custom elements to (X)HTML fragments.</li>
                                <li>Cwcm Editor: A web based intranet application used for creating and editing the European Commission’s websites contents which are stored as XML files on a content server.</li>
                                <li>Link Manager: A web based intranet application used to create and edit lists of links used in menus, “what’s new” lists or quick link lists.</li>
                            </ul>
                    </span>
                    ),
                    'role': 'Functional and technical analysis, development (J2EE, Documentum)',
                    'technologies': <ul>
                        <li>Application Server: Jakarta Tomcat 6.0, Weblogic 8 and Weblogic 10</li>
                        <li>Programming languages: Java (J2SDK 1.4.2, J2SDK6.0, J2EE1.4)</li>
                        <li>XML: XML, XSL, XSD</li>
                        <li>Web technologies: Spring MVC, HTML, CSS, JavaScript</li>
                        <li>Other: Documentum 6.5 (DFC, DQL), Eclipse IDE, Ivy, Json, Jelly, SVN, CVS, Spring 2.5, UML,
                            PuTTY
                        </li>
                    </ul>
                },
                fr: {
                    'title': 'Analyste/programmeur java pour Software AG/Acsone',
                    'client': 'Commission européenne : gestion de contenu web (04/08 – 11/11)',
                    'object': (
                        <span>
                            <ul>
    <li>Modélisation de page et de site : Le rôle du langage PST est de définir la mise en page dans un document séparé afin que la mise en page puisse être facilement réutilisée pour de nombreux documents. Le développement du moteur Java est réalisé pour interpréter ce langage de modèle et créer du HTML. Fondamentalement, ce moteur copiera le XHTML et transformera les éléments personnalisés en fragments (X)HTML.</li>
    <li>Éditeur Cwcm : Une application intranet basée sur le web utilisée pour créer et éditer le contenu des sites web de la Commission européenne, qui est stocké sous forme de fichiers XML sur un serveur de contenu.</li>
    <li>Gestionnaire de liens : Une application intranet basée sur le web utilisée pour créer et éditer des listes de liens utilisées dans des menus, des listes "What's new" ou des listes de liens rapides.</li>
</ul>
                    </span>
                    ),
                    'role': 'Analyse technique et fonctionnelle, développement (J2EE, Documentum)',
                    'technologies': <ul>
                        <li>Serveurs applicatifs: Jakarta Tomcat 6.0, Weblogic 8 and Weblogic 10</li>
                        <li>Langage de programmation: Java (J2SDK 1.4.2, J2SDK6.0, J2EE1.4)</li>
                        <li>XML: XML, XSL, XSD</li>
                        <li>Tecnologies WEB: Spring MVC, HTML, CSS, JavaScript</li>
                        <li>Autres: Documentum 6.5 (DFC, DQL), Eclipse IDE, Ivy, Json, Jelly, SVN, CVS, Spring 2.5, UML,
                            PuTTY
                        </li>
                    </ul>
                }
            },
            {
                en: {
                    'title': 'Java analyst/programmer for Software AG/Acsone',
                    'client': 'FOREM: web application (06/07 – 03/08)',
                    'object': (
                        <span>
Forem has a web application offering functionalities for job applicants and employers as posting a CV, proposing a job, searching job by criteria, proposing articles, managing its user session ...
                            My role was to analyze performances of this web application and the resource usage (mostly memory) used by Java Virtual Machine.
                            I modified parts of the application for improving those performances. Then the team had to develop new functionalities and to redesign the web site.
                    </span>
                    ),
                    'role': 'Technical analysis, development (Java, XSLT), performance tuning (JVM) and improving',
                    'technologies': 'UML, J2EE (Servlets, DOM, SAX), j2se5.0, ant 1.6.5, XML, XSLT, HTML, CSS, Tomcat 5.5, Apache 2, Weblogic 8.1, Jmeter2.2, Jprobe 7.0'
                },
                fr: {
                    'title': 'Analyste/programmeur java pour Software AG/Acsone',
                    'client': 'FOREM: application web (06/07 – 03/08)',
                    'object': (
                        <span>
                            Le Forem a une application web proposant des fonctionalités pour les demandeurs d'emploi et les employeurs telles que poster un CV, proposer un emploi, cherchers un emploir par critère, proposer des artibles, gérer les session des utilisateurs...
                            Mon rôle était d'anlyser les performances de cette application web et l'utilisation des ressources (surtout la mémoire) pour la JVM.
                            J'ai modifié certaines parties de l'application pour améliorer ces performances. Ensuite; l'équipe devait développer de nouvelles fonctionalités et cahnger le design du site web.
                    </span>
                    ),
                    'role': 'Analyse technique, développement (Java, XSLT), amélioration des performances (tuning de la JVM)',
                    'technologies': 'UML, J2EE (Servlets, DOM, SAX), j2se5.0, ant 1.6.5, XML, XSLT, HTML, CSS, Tomcat 5.5, Apache 2, Weblogic 8.1, Jmeter2.2, Jprobe 7.0'
                }
            },
            {
                en: {
                    'title': 'UML teacher at Federal Department of Finance',
                    'client': 'Federal Department of Finance (09/07 (10 days))',
                    'object': (
                        <span>Teaching UML methodology to analysts/programmers of Federal Department of Finance </span>
                    )
                },
                fr: {
                    'title': 'Professeur d\'UML pour le Département Fédéral des Finances',
                    'client': 'Le Département Fédéral des finances (09/07 (10 days))',
                    'object': (
                        <span> Enseigner la méthodologie UML à des analystes/programmeurs du Département Fédéral des Finances</span>
                    ),
                }
            },
            {
                en: {
                    'title': 'GIS engineer in French Polynesia',
                    'client': 'Cadastral administration:  cadastral application (06/04-05/07)',
                    'object': (
                        <span>
                    The cadastral application consists of 3 parts:
<ol>
  <li>The integration application, which involves the digitization of cadastral parcels into a geographic database.</li>
  <li>The conservation application, which involves updating the database due to mergers or divisions of parcels, co-ownership, etc.</li>
  <li>The advertising application, which is an intranet containing a cartographic navigator, an attribute search tool, and various systems for creating and printing cadastral documents. This application has been made available to the entire Polynesian administration with role-based access.</li>
</ol>
                    </span>
                    ),
                    'role': 'Functional analysis, technical analysis, deployment, database administration, development',
                    'technologies': (<ul>
                        <li>Databases: Oracle 8i and 9i, Access 2000</li>
                        <li>Programming languages: Visual Basic 6.0, Java 5.0</li>
                        <li>Design technologies: UML</li>
                        <li>Application Server: Tomcat</li>
                        <li>Web technologies: IIS, ASP</li>
                        <li>OS: Windows 2000 server</li>
                        <li>Other: XML, SQL, Esri GIS technologies, PDF, acrobat</li>
                    </ul>)
                },
                fr: {
                    'title': 'Ingénieur SIG en Polynésie Française',
                    'client': 'Service cadastral:  application cadastral (06/04-05/07)',
                    'object': (
                        <span>
                    L'application cadastral se composent de 3 parties :
                        <ol>
                        <li>L'application d'intégration qui consists en la numérisation de parcelles cadastrals dans une base de donnée géographique. </li>
                        <li>L'application de conservation qui consiste en la mise à jour de ka base de donnée suite à des fusions ou divisions de parcelles, des copropriétés etc… </li>
                        <li>L'applicaiton de publicité qui est un intranet contenant un navigateur cartographique, un outil de recherches attributaires et différents systèmes pour créer et imprimer des documents cadastraux. Cette application a été mise ) la disposition de toute l'administration Polynésienne avec un accès basé sur des rôles.</li>
                        </ol>
                    </span>
                    ),
                    'role': 'Functional analysis, technical analysis, deployment, database administration, development',
                    'technologies': (<ul>
                        <li>Databases: Oracle 8i and 9i, Access 2000</li>
                        <li>Programming languages: Visual Basic 6.0, Java 5.0</li>
                        <li>Design technologies: UML</li>
                        <li>Application Server: Tomcat</li>
                        <li>Web technologies: IIS, ASP</li>
                        <li>OS: Windows 2000 server</li>
                        <li>Other: XML, SQL, Esri GIS technologies, PDF, acrobat</li>
                    </ul>)
                }
            },
            {
                'en': {
                    'title': 'Software engineer at Esri BeLux (Belgium)',
                    'client': 'EDS/Flemish Community (02/02-05/04)',
                    'object': (
                        <span>'Development of a Java Webstart application for managing works on routes of Belgium.</span>),
                    'role': (<>Java analyst – programmer; participation in the implementation, the web architecture and the
                        functional and technical analysis.</>),
                    'technologies': (<ul>
                        <li>Programming languages: Java 1.4, Swing, J2EE (servlet)</li>
                        <li>Application Server: Tomcat 1.4</li>
                        <li>XML: XML, XSL-T</li>
                        <li>Web technologies: Jsp, Java Web Start</li>
                        <li>Other: Jbuilder, Eclipse, Apache Webserver</li>
                    </ul>)
                },
                'fr': {
                    'title': 'Ingénieur logiciel à Esri BeLux (Belgium)',
                    'client': 'Communauté flamande (02/02-05/04)',
                    'object': (
                        <span>'Développement d'une application Java Webstart pour gérer les chantiers sur les routes de Belgique.</span>),
                    'role': (<>Analyste – programmeur java; participation à l'implémentation, l'architecture web et
                        l'analyse technique et fonctionnelle.</>),
                    'technologies': (<ul>
                        <li>Languages de programmation: Java 1.4, Swing, J2EE (servlet)</li>
                        <li>Server d'application: Tomcat 1.4</li>
                        <li>XML: XML, XSL-T</li>
                        <li>Technologies Web: Jsp, Java Web Start</li>
                        <li>Autres: Jbuilder, Eclipse, Apache Webserver</li>
                    </ul>)
                }
            }
        ]

        return (
            <Collapsible trigger={title}>
                {
                    experiences.map((experience, index) => {
                        return (
                            <div className='jobs'>
                                <div
                                    className='job-title'>{language === 'EN' ? experience.en.title : experience.fr.title}</div>
                                <div
                                    className='job-location'>{language === 'EN' ? experience.en.client : experience.fr.client}</div>
                                <div className='job-details'><span
                                    className='job-details-key'>Object</span> : {language === 'EN' ? experience.en.object : experience.fr.object}
                                </div>
                                {experience.en.role ?
                                    <div className='job-details'><span
                                        className='job-details-key'>Role</span> : {language === 'EN' ? experience.en.role : experience.fr.role}
                                    </div> : <></>}
                                {experience.en.technologies ?
                                    <div className='job-details'><span
                                        className='job-details-key'>Technologies</span> : {language === 'EN' ? experience.en.technologies : experience.fr.technologies}
                                    </div> : <></>}
                                <br/>
                            </div>
                        )
                    })
                }
            </Collapsible>
        );
    }
;

export default WorkExperience;