import React from 'react';
import Collapsible from 'react-collapsible';
import styles from './PersonalInformation.scss'

const titleObj = {en:'Personal Information',fr:'Informations personnelles'}

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth()
const age = currentYear -1979 - (currentMonth < 5 ? 1 : 0)

const personalInformationKeys = {
    'name': {'en': 'Name', 'fr': 'Nom'}
    , 'address': {'en': 'Address', 'fr': 'Adresse'}
    , 'email': {'en': 'E-mail', 'fr': 'E-mail'}
    , 'mobile': {'en': 'Mobile', 'fr': 'Mobile'}
    , 'nationality': {'en': 'Nationality', 'fr': 'Nationalité'}
    , 'age': {'en': 'Age', 'fr': 'Âge'}
}
const personalInformationValues = {
    'name': 'Grégory Dony'
    , 'address': 'rue François Marcq, 19 7030 Mons'
    , 'email': 'gregorydony@gmail.com'
    , 'mobile': '+32473182813'
    , 'nationality': {'en':'Belgian', 'fr':'belge'}
    , 'age': age.toString()
}

const profileImage = {
    name: 'Gregory Dony',
    imageUrl: 'photo-profile.png',
    imageHeight: 1346/4,
    imageWidth: 981/4
};


const PersonalInformation = ({language}) => {

    const title  = language==='EN' ? titleObj.en : titleObj.fr
    const keyValueSeparator = ': '
    const nameRow = (language==='EN' ? personalInformationKeys.name.en : personalInformationKeys.name.fr ) + keyValueSeparator + personalInformationValues.name
    const addressRow = (language==='EN' ? personalInformationKeys.address.en : personalInformationKeys.address.fr ) + keyValueSeparator + personalInformationValues.address
    const emailRow = (language==='EN' ? personalInformationKeys.email.en : personalInformationKeys.email.fr ) + keyValueSeparator + personalInformationValues.email
    const ageRow = (language==='EN' ? personalInformationKeys.age.en : personalInformationKeys.age.fr ) + keyValueSeparator + personalInformationValues.age
    const nationalityRow = language==='EN' ? personalInformationKeys.nationality.en + keyValueSeparator + personalInformationValues.nationality.en : personalInformationKeys.nationality.fr + keyValueSeparator + personalInformationValues.nationality.fr
    return (
        <Collapsible trigger={title}>
            <img
                className="avatar"
                src={profileImage.imageUrl}
                alt={'Photo of ' + profileImage.name}
                style={{
                    width: profileImage.imageWidth,
                    height: profileImage.imageHeight
                }}
            />
            <ul>
                <li>{nameRow}</li>
                <li>{addressRow}</li>
                <li>{emailRow}</li>
                <li>{ageRow}</li>
                <li>{nationalityRow}</li>
            </ul>
        </Collapsible>
    );
};

export default PersonalInformation;