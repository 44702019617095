import Collapsible from "react-collapsible";
import React from "react";

const titleObj = {en:'Skills Summary',fr:'Compétences'}

const SkillsSummary = ({language}) => {

    const title  = language==='EN' ? titleObj.en : titleObj.fr
    const contentEN = (
            <>
                <ul>
                    <li>
                        <strong>Domains of expertise :</strong> Cloud Native Architecture, IAM (Identity & Access Management), Kubernetes
                    </li>
                    <li>
                        <strong>Programming languages :</strong> Java 11, Node.js 16, Javascript, Angular 11, React.js, HTML, Groovy
                    </li>
                    <li>
                        <strong>Frameworks :</strong> Spring boot, Nest.js, Angular, React.js
                    </li>
                    <li>
                        <strong>Data formats :</strong> JSON, YAML
                    </li>
                    <li>
                        <strong>Database :</strong> Couchbase, Oracle RDBMS
                    </li>
                    <li>
                        <strong>Cloud Native :</strong> Kubernetes, OpenShift, Docker, Redhat s2i (source-to-image), JIB, ArgoCD, Quay, Gitlab Runner k8s executors, Jenkins cloud agents
                    </li>
                    <li>
                        <strong>IAM :</strong> OpenId Connect, OAuth2, CAS (Central Authentication Service), SAML (Security Assertion Mark-up Language), WS-Federation, WS-Trust and SSL/TLS
                    </li>
                    <li>
                        <strong>Security :</strong> CyberArk-Conjur, TLS, Mutual authentication, Encryption, SonarQube (SAST)
                    </li>
                    <li>
                        <strong>Data communication & Networks :</strong> TCP/IP, HTTP, FTP
                    </li>
                    <li>
                        <strong>VCS :</strong> git, gitlab, github, SVN
                    </li>
                    <li>
                        <strong>Build & packaging :</strong> Maven 3, npm 9, ant
                    </li>
                    <li>
                        <strong>CI/CD :</strong> GitLab CI, Jenkins, Tekton
                    </li>
                    <li>
                        <strong>IDE :</strong> Intellij 2023, VS code
                    </li>
                    <li>
                        <strong>Application servers/web servers :</strong> Netty, Tomcat 9, Oracle Weblogic 12, Apache2.0
                    </li>
                    <li>
                        <strong>Operating systems :</strong> Linux, Windows
                    </li>
                    <li>
                        <strong>Methodologies and case tools :</strong> UML, OO, Design Patterns, Entity-association model/relational model, Client/server.
                    </li>
                    <li>
                        <strong>GIS :</strong> ArcObjects10.x, ArcGIS10.x, MOJE2.0, ArcIMS9.1, ArcGIS Engine, WMS, WFS, GML, Geotools, Google Map API, KML, Google Earth
                    </li>
                </ul>
            </>
    )
    const contentFR = (
        <>
            <ul>
                <li>
                    <strong>Domaines d'expertise :</strong> Architecture Cloud Native, IAM (Gestion des identités et des accès), SIG (Système d'information géographique)
                </li>
                <li>
                    <strong>Languages de programmation :</strong> Java 11, Node.js 16, Javascript, Angular 11, React.js, HTML, Groovy
                </li>
                <li>
                    <strong>Frameworks :</strong> Spring boot, Nest.js, Angular, React.js
                </li>
                <li>
                    <strong>Formats de données :</strong> JSON, YAML
                </li>
                <li>
                    <strong>Bases de données :</strong> Couchbase, Oracle RDBMS
                </li>
                <li>
                    <strong>Cloud Native :</strong> Kubernetes, OpenShift, Docker, Redhat s2i (source-to-image), JIB, ArgoCD, Quay, Gitlab Runner k8s executors, Jenkins cloud agents
                </li>
                <li>
                    <strong>IAM :</strong> OpenId Connect, OAuth2, CAS (Central Authentication Service), SAML (Security Assertion Mark-up Language), WS-Federation, WS-Trust and SSL/TLS
                </li>
                <li>
                    <strong>Communication de données et Réseaux :</strong> TCP/IP, HTTP, FTP, SSL/TLS
                </li>
                <li>
                    <strong>VCS :</strong> git, gitlab, github, SVN
                </li>
                <li>
                    <strong>Build & packaging :</strong> Maven 3, npm 9, ant
                </li>
                <li>
                    <strong>CI/CD :</strong> GitLab CI, Jenkins, Tekton
                </li>
                <li>
                    <strong>IDE :</strong> Intellij 2023, VS code
                </li>
                <li>
                    <strong>Serveurs d'application/Serveurs web :</strong> Netty, Tomcat 9, Oracle Weblogic 12, Apache2.0
                </li>
                <li>
                    <strong>Systèmes d'exploitation :</strong> Linux, Windows
                </li>
                <li>
                    <strong>Méthodologies et outils de modélisation :</strong> UML, OO, Design Patterns, Entity-association model/relational model, Client/server.
                </li>
                <li>
                    <strong>SIG :</strong> ArcObjects10.x, ArcGIS10.x, MOJE2.0, ArcIMS9.1, ArcGIS Engine, WMS, WFS, GML, Geotools, Google Map API, KML, Google Earth
                </li>
            </ul>
        </>
    )
    return (
        <Collapsible trigger={title}>
            <ul>
                {language === 'EN' ? contentEN : contentFR}
            </ul>
        </Collapsible>
    );
};

export default SkillsSummary;