import Collapsible from "react-collapsible";
import React from "react";

const titleObj = {en:'Interests',fr:'Centres d\'intérêt'}

const Interests = ({language}) => {

    const title  = language==='EN' ? titleObj.en : titleObj.fr
    const contentEN = (
        <>
            I'm the happy co-owner and developer of <a href="https://www.pasifika.org">Pasifika.org</a> website dedicated to the learning of polynesian languages
        </>)

    const contentFR = (
        <>
            Je suis l'heureux co-propriétaire et développeur de <a href="https://www.pasifika.org">Pasifika.org</a>, site web dédié à l'apprentissage des langues polynésiennes
        </>)
    return (
        <Collapsible trigger={title}>
            <ul>
                {language === 'EN' ? contentEN : contentFR}
            </ul>
        </Collapsible>
    );
};

export default Interests;