import Collapsible from "react-collapsible";
import React from "react";

const titleObj = {en: 'Objective', fr: 'Objectif professionnel'}

const Objective = ({language}) => {

    const title = language === 'EN' ? titleObj.en : titleObj.fr

    const contentEN = (
        <>
            <p>Throughout my 22-year career, I have consistently directed my professional choices in a constant pursuit
                of learning.</p>

            <p>My journey began in the field of <strong>Geographic Information Systems (GIS)</strong>, initially as an
                employee at Esri
                BeLux and later as an independent contractor at the IT Department of French Polynesia. Active
                involvement in Cadastre information and the development of GIS for services such as the Fisheries
                Department and the Pearl Culture Department marked these early years. Although my involvement in this
                field has diminished in recent years, my passion persists, as evidenced by my Geomatics certification
                obtained at UCL in 2017.</p>

            <p>Upon returning to Belgium, I honed my <strong>Java</strong> skills through rewarding assignments with
                Acsone. Later, I
                seized the opportunity to work within the Information Technology Directorate of the European Commission
                on Identity and Authorization Management. My experience with the remarkable team dedicated to the
                EuLogin authentication application (formerly ECAS), involving the implementation of authentication and
                authorization protocols, federation solutions, and authentication methods, further fueled my interest in
                the <strong>Identity and Access Management (IAM)</strong> domain.</p>

            <p>My journey then led me to join the digital architecture team at Proximus, where I take on the roles
                of <strong>CI/CD architect</strong> and <strong>Microservices architect</strong> within a dynamic and
                skilled team. Working primarily on a
                private <strong>OpenShift</strong> cloud, I have developed in-depth expertise
                in <strong>Kubernetes</strong>, both at the architectural
                and operational levels, as well as in terms of security.</p>

            <p>I also acquired a good knowledge of <strong>Angular</strong> thanks to the development of a CI/CD
                dashboard during my Proximus experience, and as a
                developer and owner of the site <a href="https://pasifika.org">Pasifika.org</a> (which I have just
                migrated to angular 18)</p>

            <p>Throughout my experiences, I have observed that my performance level is closely linked to the
                satisfaction I derive from my work. This satisfaction primarily depends on two factors:</p>

            <ul>
                <li>A dynamic team of 5 to 10 individuals who share my thirst for learning and commitment to
                    excellence.
                </li>
                <li>Architectural or application challenges; a day without learning is a day lost.</li>
            </ul>

            <p>This is the most important: I am not afraid to learn new technologies, whether it is new
                languages, new architectures, new platforms. I think that my adaptability and my motivation to integrate into a new environment are my
                main strengths.
            </p>

            <p>I particularly appreciate that the team follows a well-defined but not invasive work methodology (DoD, DoR, Ceremonies, etc.) and has clear governance. If necessary, I am prepared to contribute to the
                establishment of this governance.</p>

            <p>Additionally, I enjoy working in a hybrid mode, such as 2 days on-site and 3 days from home, for example.
                I'm ok to work full-remote too even if I like meeting my colleagues from time to time.
                Daily commuting does not suit me.</p>

            <p>Finally, I think it is important to start a mission with a suitable rate. Let agree about the minimum rate at the beginning of the discussion to avoid loosing time.</p>
        </>
    )

    const contentFR = (
        <>
            <p> Au cours de mes 22 années de carrière, j'ai toujours orienté mes choix professionnels en quête constante
                d'apprentissage.</p>

            <p> Mon parcours a débuté dans le domaine des <strong>Systèmes d'Information Géographique (SIG)</strong>, en
                tant qu'employé
                chez Esri BeLux, puis en tant qu'indépendant au Service Informatique de la Polynésie Française. Mon
                implication active dans la mise à jour du Cadastre et le développement de SIG pour des services tels que
                le Service de la Pêche ou le Service de la Perliculture a marqué ces premières années. Bien que mon
                activité dans ce domaine se soit réduite ces dernières années, ma passion persiste, comme en témoigne ma
                certification en Géomatique obtenue à l'UCL en 2017.</p>

            <p> De retour en Belgique, j'ai affiné mes compétences en <strong>Java</strong> grâce à des missions
                enrichissantes avec la
                société Acsone. Plus tard, j'ai saisi l'opportunité de travailler au sein de la Direction informatique
                de la Commission Européenne sur la Gestion d'Identité et d'Autorisation. Mon expérience au sein de la
                remarquable équipe dédiée à l'application d'authentification EuLogin (anciennement ECAS), impliquant la
                mise en place de protocoles d'authentification et d'autorisation, des solutions de fédération et des
                méthodes d'authentification, a renforcé mon intérêt pour le domaine de l' <strong>IAM (Identity and
                    Access
                    Management)</strong>.</p>

            <p> Mon parcours m'a ensuite conduit à rejoindre l'équipe d'architecture digitale de Proximus, où je cumule
                les rôles d'<strong>architecte CI/CD</strong> et d'<strong>architecte Microservices</strong> au sein
                d'une équipe dynamique et compétente.
                Travaillant principalement sur un cloud privé <strong>OpenShift</strong>, j'ai développé une expertise
                approfondie en
                <strong>Kubernetes</strong>, tant au niveau architectural qu'opérationnel et en matière de sécurité.</p>

            <p> J'ai aussi acquis une bonne connaissance d'Angular grâce au développement d'un dashboard CI/CD lors de
                mon expérience Proximus, et en tant que
                développeur et propriétaire du site <a href="https://pasifika.org">Pasifika.org</a> (que je viens de
                migrer en angular 18)</p>

            <p> Tout au long de ces expériences, j'ai constaté que mon niveau de performance est étroitement lié à la
                satisfaction que je trouve dans mon travail. Cette satisfaction dépend essentiellement de deux facteurs
                :</p>

            <ul>
                <li> Une équipe dynamique de 5 à 10 personnes partageant ma soif d'apprentissage et ma volonté
                    d'excellence.
                </li>
                <li> Des défis architecturaux ou applicatifs ; une journée sans apprentissage est une journée perdue.
                </li>
            </ul>

            <p>Ceci est le paragraphe le plus important : je n'ai pas peur d'apprendre de nouvelles technologies, que ce
                soit de nouveaux languages, de nouvelles architectures.
                de nouvelles plateformes. Je pense que ma capacité d'adaptation et ma motivation pour m'intégrer dans un
                nouvel environnement sont mes principales forces.
            </p>

            <p> J'apprécie particulièrement que l'équipe suive une <strong>méthodologie</strong> de travail <strong>bien définie (DoD, DoR,
                cérémonies, ...)</strong> mais pas invasive et bénéficie d'une gouvernance claire. Si nécessaire, je suis prêt à contribuer à
                la mise en place de cette gouvernance.</p>

            <p> En outre, j'apprécie le travail en mode hybride, avec par exemple 2 jours par semaine sur site et 3 en
                télétravail. Le <i>Full remote</i> est envisageable même si je préfère rencontrer mes collègues de temps en temps.
                Les déplacements quotidiens ne me conviennent pas. </p>

            <p> Enfin, je pense qu'il est important de commencer une mission avec un tarif qui convient. Je ne négocie qu'à partir d'un tarif de 800 EUR.</p>

        </>
    )

    return (
        <Collapsible trigger={title}>
            <ul>
                {language === 'EN' ? contentEN : contentFR}
            </ul>
        </Collapsible>
    );
};

export default Objective;